$primary: #FF6978;
$body-bg: #F2F6F9;
$secondary: #F2C2C2;
$font-family-base: 'Karla', sans-serif !important;

@import "~bootstrap/scss/bootstrap";


.tab-bottom-line {
  flex: 1;
  border-bottom: 5px solid $secondary;
  padding-bottom: 15px;
  font-size: 17px;

  @media (max-width: 420px) {
    font-size: 13px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
}

.tab-bottom-line-complete {
  border-bottom: 5px solid $primary;
}

.btn {
  //color: #FFFFFF;
}
